// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/bg1.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-background[data-v-1abc445a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 6rem;\n  position: relative;\n  background-size: 100% 100%;\n}\n.header-avatar[data-v-1abc445a] {\n  position: absolute;\n  bottom: 2.13333rem;\n  left: 0.26667rem;\n}\n.header-name[data-v-1abc445a] {\n  position: absolute;\n  bottom: 2.61333rem;\n  left: 2rem;\n  color: black;\n  font-size: 0.37333rem;\n}\n.header-name[data-v-1abc445a]:hover {\n  cursor: pointer;\n}\n.storeInfo-box[data-v-1abc445a] {\n  box-sizing: border-box;\n  padding: 0.53333rem 0.26667rem 0.26667rem;\n}\n.storeInfo-box div[data-v-1abc445a] {\n  line-height: 0.85333rem;\n}\n.storeInfo-name[data-v-1abc445a] {\n  font-weight: bold;\n}\n.storeInfo-name2[data-v-1abc445a] {\n  color: #2c2c2c;\n}\n.storeInfo-name2 .van-icon[data-v-1abc445a] {\n  color: black;\n  margin-right: 0.13333rem;\n}\n[data-v-1abc445a] .van-button__text {\n  color: #323233;\n}\n", ""]);
// Exports
module.exports = exports;
