import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getURLParam } from '@/utils/common';
import { storeInfo } from '@/api/store';
import { logout as _logout } from '@/api/login';
import { userInfo } from '@/api/user';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Tabbar.name, _Tabbar), _defineProperty(_components, _TabbarItem.name, _TabbarItem), _defineProperty(_components, _Icon.name, _Icon), _defineProperty(_components, _Toast.name, _Toast), _defineProperty(_components, _Dialog.name, _Dialog), _components),
  data: function data() {
    return {
      isLogin: Boolean(localStorage.getItem('TOKEN')),
      tabbarActive: 'personal',
      storeInfoDialog: false,
      storeInfo: {},
      // 门店信息
      userInfo: {}
    };
  },
  created: function created() {
    if (localStorage.getItem('TOKEN')) {
      this.getUserInfo();
    }
  },
  methods: {
    // 切换菜单
    changeTabbar: function changeTabbar(e) {
      if (e === 'home') {
        this.$router.push({
          name: 'home'
        });
      } else if (e === 'case') {
        this.$router.push({
          name: 'case'
        });
      } else if (e === 'package') {
        this.$router.push({
          name: 'package'
        });
      } else if (e === 'personal') {
        this.$router.push({
          name: 'personal'
        });
      }
    },
    // 用户信息
    getUserInfo: function getUserInfo() {
      var _this = this;

      userInfo().then(function (res) {
        if (res.error.code === 0) {
          _this.userInfo = res.data;
          var beforeStoreId = res.data.store_id; // 之前的id

          var beforeStoreName = res.data.store_name; // 之前的name

          var nowStoreId = localStorage.getItem('adl_store_id'); // 现在扫码的id
          // const nowStoreName = localStorage.getItem('adl_store_name') // 现在扫码的name
          // 判断是否需要改绑门店
          // if (
          //   getURLParam('fromLogin') &&
          //   getURLParam('fromLogin') === '1' &&
          //   Number(nowStoreId) !== beforeStoreId
          // ) {
          //   Dialog.confirm({
          //     title: '',
          //     message: `检测到您之前是在门店【${beforeStoreName}】,是否改绑到门店【${nowStoreName}】`,
          //     showCancelButton: true
          //   })
          //     .then(() => {
          //       changeStore({ store: nowStoreId }).then(() => {
          //         if (res.error.code === 0) {
          //           Toast('改绑成功')
          //           this.$router.replace({ path: '/refresh' })
          //         }
          //       })
          //     })
          //     .catch(() => {
          //       localStorage.setItem('adl_store_id', beforeStoreId)
          //       localStorage.setItem('adl_store_name', beforeStoreName)
          //     })
          // }
          // 如果之前在门店1注册，之后扫别的门店也是登录门店1,并提示

          if (getURLParam('fromLogin') && getURLParam('fromLogin') === '1' && Number(nowStoreId) !== beforeStoreId) {
            _Dialog.confirm({
              title: '',
              message: "\u60A8\u5DF2\u7ECF\u6CE8\u518C\u5230\u3010".concat(beforeStoreName, "\u3011"),
              showCancelButton: false
            }).then(function () {}).catch(function () {});

            localStorage.setItem('adl_store_id', beforeStoreId);
            localStorage.setItem('adl_store_name', beforeStoreName);
          }
        }
      });
    },
    // 登录/注册
    goLogin: function goLogin() {
      this.$router.push({
        name: 'login'
      });
    },
    // 门店信息
    showStoreInfo: function showStoreInfo() {
      var _this2 = this;

      storeInfo().then(function (res) {
        if (res.error.code === 0) {
          _this2.storeInfoDialog = true;
          _this2.storeInfo = res.data[0];
        }
      });
    },
    // 退出登录
    logout: function logout() {
      var _this3 = this;

      _Dialog.confirm({
        title: '',
        message: '确定退出登录吗？',
        showCancelButton: true
      }).then(function () {
        _logout().then(function (res) {
          if (res.error.code === 0) {
            localStorage.removeItem('TOKEN');

            _Toast('退出成功');

            _this3.$router.replace({
              path: '/refresh'
            });
          }
        });
      }).catch(function () {});
    }
  }
};