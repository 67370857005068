import request from '@/utils/request'; // 获取用户信息

export function userInfo(data) {
  return request({
    url: '/user',
    method: 'get',
    params: data
  });
} // 上传验光报告

export function report(data) {
  return request({
    url: '/user/report',
    method: 'post',
    data: data
  });
} // 改绑门店

export function changeStore(data) {
  return request({
    url: '/user/bind-store',
    method: 'post',
    data: data
  });
}
export function urlScheme(data) {
  return request({
    url: '/applet/url-scheme',
    method: 'get',
    params: data
  });
}