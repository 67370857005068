var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "header-background" },
        [
          _c("van-image", {
            staticClass: "header-avatar",
            attrs: {
              round: "",
              width: "60px",
              height: "60px",
              src:
                _vm.userInfo.portrait || require("@/assets/defaultAvatar.png"),
            },
          }),
          _c(
            "div",
            {
              staticClass: "header-name",
              on: {
                click: function ($event) {
                  _vm.isLogin ? "" : _vm.goLogin()
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.isLogin ? _vm.userInfo.nickname : "登录/注册 >") +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.isLogin
        ? _c(
            "div",
            [
              _c("van-cell", {
                attrs: { title: "门店信息", "is-link": "" },
                on: { click: _vm.showStoreInfo },
              }),
              _c("van-cell", {
                attrs: { title: "我的订单", "is-link": "", to: "package" },
              }),
              _c("van-cell", {
                attrs: { title: "预约信息", "is-link": "", to: "reservation" },
              }),
              _c("van-cell", {
                attrs: {
                  title: "上传报告",
                  "is-link": "",
                  to: {
                    name: "uploadReport",
                    query: {
                      report_image: _vm.userInfo.report_image,
                      report_fail_reason: _vm.userInfo.report_fail_reason,
                      report_status:
                        _vm.userInfo.report_image &&
                        _vm.userInfo.report_image.length !== 0
                          ? _vm.userInfo.report_status
                          : 99,
                    },
                  },
                },
              }),
              _c("van-cell", {
                attrs: { title: "退出登录", "is-link": "" },
                on: { click: _vm.logout },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-tabbar",
        {
          staticClass: "page-tottom-tabbar",
          on: { change: _vm.changeTabbar },
          model: {
            value: _vm.tabbarActive,
            callback: function ($$v) {
              _vm.tabbarActive = $$v
            },
            expression: "tabbarActive",
          },
        },
        [
          _c("van-tabbar-item", { attrs: { name: "home", icon: "home-o" } }, [
            _vm._v("首页"),
          ]),
          _c("van-tabbar-item", { attrs: { name: "case", icon: "star-o" } }, [
            _vm._v("数据"),
          ]),
          _c("van-tabbar-item", { attrs: { name: "package", icon: "bag-o" } }, [
            _vm._v("订单"),
          ]),
          _c(
            "van-tabbar-item",
            { attrs: { name: "personal", icon: "friends-o" } },
            [_vm._v("我的")]
          ),
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          attrs: { title: "", "show-cancel-button": false },
          model: {
            value: _vm.storeInfoDialog,
            callback: function ($$v) {
              _vm.storeInfoDialog = $$v
            },
            expression: "storeInfoDialog",
          },
        },
        [
          _c("div", { staticClass: "storeInfo-box" }, [
            _c("div", { staticClass: "storeInfo-name" }, [
              _vm._v(_vm._s(_vm.storeInfo.name)),
            ]),
            _c(
              "div",
              { staticClass: "storeInfo-name2" },
              [
                _c("van-icon", { attrs: { name: "location-o" } }),
                _vm._v(_vm._s(_vm.storeInfo.full_address) + " "),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "storeInfo-name2" },
              [
                _c("van-icon", { attrs: { name: "underway-o" } }),
                _vm._v(_vm._s(_vm.storeInfo.open_time) + " "),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "storeInfo-name2" },
              [
                _c("van-icon", { attrs: { name: "phone-circle-o" } }),
                _vm._v(_vm._s(_vm.storeInfo.phone) + " "),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }